<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header @clickRight="clickRight">
      <template>合成阁</template>
      <template v-slot:right>合成记录</template>
    </Header>
    <div style="padding: 20px">
      <!-- 搜索 -->
      <div class="search">
        <div class="left">
          <input
            v-model="searchVal"
            @keydown.enter="search"
            type="text"
            placeholder="输入商品名称"
          />
        </div>
        <div class="right">
          <div class="btn" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div class="container" ref="container">
      <div class="def" v-if="!list.length">
        <img src="../../../../static/image/hezi2.png" alt="" />
        <p>暂无数据</p>
      </div>
      <div style="padding: 0 20px">
        <!-- list -->
        <div class="list">
          <div
            class="bg"
            v-for="item in list"
            :key="item.id"
            @click="
              $store.commit('REMOVE_NEEDGOODS');
              $router.push(`/mergeContent?id=${item.id}`);
            "
          >
            <div class="item">
              <div class="img">
                <img :src="item.coverPic" alt="" />
                <div
                  class="over"
                  v-if="new Date(item.endTime).getTime() <= Date.now()"
                >
                  <img src="../../../../static/image/over.png" alt="" />
                </div>
              </div>
              <div class="title">{{ item.goods.name }}</div>
              <div class="total">
                <div class="left">限量</div>
                <div class="right">{{ item.limitTotalNum || 0 }}份</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Merge',
  data() {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        name: '',
      },
      searchVal: '',
      list: [],
      noMore: false,
      initLoading: true,
    };
  },
  mounted() {
    this.initLoading = true;
    this.getGoodsComposeList();
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.moreCb);
    }
  },
  methods: {
    moreCb(e) {
      const target = e.target;
      if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
        console.log('到底了');
        if (!this.noMore) {
          this.query.pageindex++;
          this.getGoodsComposeList('more');
        }
      }
    },
    search() {
      this.query.name = this.searchVal;
      this.query.pageindex = 1;
      this.getGoodsComposeList();
    },
    clickRight() {
      this.$router.push('/mergeRecord');
    },
    async getGoodsComposeList(isMore) {
      try {
        const data = await this.$api.goodsComposeList(this.query);
        if (data.code === 0) {
          if (isMore) {
            this.list = this.list.concat(data.data || []);
          } else {
            this.list = data.data || [];
          }
          if ((data.data || []).length < this.query.pagesize) {
            this.noMore = true;
          }
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
        return this.$toast(error.message);
      } finally {
        this.initLoading = false;
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener('scroll', this.moreCb, true);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 120px;
  overflow: auto;
}
.search {
  display: flex;
  background: #f8f8f8;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .left {
    display: flex;
    align-items: center;
    flex: 1;
    input {
      font-size: 14px;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
  .btn {
    font-size: 14px;
    color: #999999;
  }
}
.def {
  text-align: center;
  margin-top: 30px;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 10px;
  .bg {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    .item {
      box-shadow: 0px 5px 20px 5px rgba(1, 15, 45, 0.08);
      padding-bottom: 10px;
      .img {
        width: 100%;
        position: relative;
        height: 170px;
        img {
          width: 100%;
          vertical-align: middle;
          height: 100%;
        }
        .over {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: rgba(98, 98, 98, 0.8);
          img {
            height: auto;
            width: 70%;
            margin: 0 auto;
          }
        }
      }
      .title {
        font-size: 16px;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 10px;
      }
      .total {
        display: flex;
        margin-top: 10px;
        padding: 0 10px;
        .left {
          font-size: 12px;
          color: #fff;
          background: #abaab9;
          padding: 2px 5px;
        }
        .right {
          font-size: 12px;
          padding: 2px 5px;
          background: #f3f4f8;
          color: #4c4b58;
        }
      }
    }
  }
}
</style>
